import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CancelablePromise,
  Message,
  messagesList,
  PaginatedMessageList
} from '../../services/api';
import handleAPIError from '../../services/api/handleAPIError';
import { AppDispatch } from '../../Store';

type MessagesSliceState = {
  messages: { [key: number]: Message };
  unread: number;
  messages_updating: boolean;
};

const initialState: MessagesSliceState = {
  messages: {},
  unread: 0,
  messages_updating: false
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState: initialState,
  reducers: {
    retrieveMessageListStarted(state) {
      state.messages_updating = true;
    },
    retrieveMessageListSuccess(
      state,
      action: PayloadAction<PaginatedMessageList>
    ) {
      state.messages_updating = false;
      let unread = 0;
      for (let message of action.payload.results || []) {
        if (message.status !== 'Read') {
          unread++;
        }
        state.messages[message.id] = message;
      }
      state.unread = unread;
    },
    retrieveMessageListFailed(state, action) {
      state.messages_updating = false;
    }
  }
});

export const {
  retrieveMessageListStarted,
  retrieveMessageListSuccess,
  retrieveMessageListFailed
} = messagesSlice.actions;
export default messagesSlice.reducer;

let retrieveMessagesList: CancelablePromise<PaginatedMessageList>;
export const retrieveMessageList = () => async (dispatch: AppDispatch) => {
  if (retrieveMessagesList) {
    retrieveMessagesList.cancel();
  }

  dispatch(retrieveMessageListStarted());
  retrieveMessagesList = messagesList({});

  retrieveMessagesList
    .then((data) => {
      dispatch(retrieveMessageListSuccess(data));
    })
    .catch((ex) => {
      handleAPIError(
        ex,
        "Couldn't retrieve messages!",
        dispatch,
        retrieveMessageListFailed
      );
    });
};
