import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../Store';
import {
  CancelablePromise,
  PaginatedTransactionList,
  PatchedTransactionRequest,
  TransactionRequest,
  Transaction,
  transactionsList,
  transactionsAttachmentsCreate,
  transactionsCreate,
  transactionsDestroy,
  transactionsPartialUpdate,
  transactionsRetrieve
} from '../../services/openapi';
import handleAPIError, {
  APIErrorMessage
} from '../../services/api/handleAPIError';

export type TransactionSliceState = {
  transactions: Transaction[];
  nr_of_transactions: number;
  transactions_updating: boolean;
  background: boolean;
  showColumns: {
    date: boolean;
    project: boolean;
    driver: boolean;
    purchaser: boolean;
    cmr_no: boolean;
    assortment: boolean;
    amount: boolean;
    description: boolean;
    attachments: boolean;
    ticket_no: boolean;
    projectcode_forestcompany: boolean;
    projectcode_forestowner: boolean;
  };
  selected: Transaction[];
};
export type ColumnTypes = keyof TransactionSliceState['showColumns'];

const initialState: TransactionSliceState = {
  transactions: [],
  nr_of_transactions: 0,
  transactions_updating: false,
  background: false,
  showColumns: {
    date: true,
    project: true,
    driver: true,
    purchaser: true,
    cmr_no: false,
    assortment: true,
    amount: true,
    description: true,
    attachments: true,
    projectcode_forestcompany: false,
    projectcode_forestowner: false,
    ticket_no: false
  },
  selected: []
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: initialState,
  reducers: {
    retrieveTransactionListStarted(state, action) {
      state.transactions_updating = true;
      state.background = action.payload.background;
    },
    retrieveTransactionListSuccess(
      state,
      action: PayloadAction<PaginatedTransactionList>
    ) {
      const payload = action.payload;
      state.transactions_updating = false;
      state.nr_of_transactions = payload.count || 0;
      state.transactions = payload.results || [];
    },
    retrieveTransactionListFailed(
      state,
      action: PayloadAction<APIErrorMessage>
    ) {
      state.transactions_updating = false;
    },
    setSelected(state, action: PayloadAction<Transaction[]>) {
      state.selected = action.payload;
    },
    setColumns(
      state,
      action: PayloadAction<{
        column: keyof TransactionSliceState['showColumns'];
        visible: boolean;
      }>
    ) {
      state.showColumns[action.payload.column] = action.payload.visible;
    },
    retrieveTransactionSuccess(state, action) {
      state.transactions_updating = false;
      let transaction = action.payload.transaction;
      state.transactions = state.transactions.map((t) => {
        return t.id == transaction.id ? transaction : t;
      });
    },
    retrieveTransactionFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.transactions_updating = false;
    },
    deleteTransactionSuccess(state, action: PayloadAction<number>) {
      state.transactions = state.transactions.filter(
        (t) => t.id !== action.payload
      );
    }
  }
});

export const {
  retrieveTransactionListStarted,
  retrieveTransactionListSuccess,
  retrieveTransactionListFailed,
  setSelected,
  setColumns,
  deleteTransactionSuccess
} = transactionsSlice.actions;
export const { retrieveTransactionSuccess, retrieveTransactionFailed } =
  transactionsSlice.actions;
export default transactionsSlice.reducer;

let retrieveTransactionsList: CancelablePromise<PaginatedTransactionList>;
export type retrieveTransactionFilters = {
  purchaser?: string;
  project?: string;
  cmr?: string;
  driver?: string;
  state?: string;
  dateMin?: string;
  dateMax?: string;
  assortment?: string;
  ordering?: string;
};
export const retrieveTransactionList =
  (
    background = false,
    page: number,
    page_size: number,
    filters: retrieveTransactionFilters
  ) =>
  async (dispatch: AppDispatch) => {
    if (retrieveTransactionsList) {
      retrieveTransactionsList.cancel();
    }
    dispatch(retrieveTransactionListStarted({ background: background }));

    //@ts-ignore
    retrieveTransactionsList = transactionsList({
      ...filters,
      page: page,
      pageSize: page_size
    });

    retrieveTransactionsList
      .then((data) => {
        dispatch(retrieveTransactionListSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't retrieve transactions!",
          dispatch,
          retrieveTransactionListFailed
        );
      });
  };

export const retrieveTransaction =
  (id: number) => async (dispatch: AppDispatch) => {
    transactionsRetrieve({ id })
      .then((transaction) => {
        dispatch(retrieveTransactionSuccess({ transaction: transaction }));
      })
      .catch(function (ex) {
        handleAPIError(
          ex,
          'Could not get transaction!',
          dispatch,
          retrieveTransactionFailed
        );
      });
  };

export const createTransaction =
  (data: TransactionRequest, callback?: (transaction: Transaction) => void) =>
  async (dispatch: AppDispatch) => {
    transactionsCreate({ requestBody: data })
      .then(function (transaction) {
        dispatch(retrieveTransactionSuccess({ transaction: transaction }));
        callback && callback(transaction);
      })
      .catch(function (ex) {
        handleAPIError(ex, 'Could not create transaction!', dispatch);
      });
  };

export const updateTransaction =
  (
    id: number,
    data: PatchedTransactionRequest,
    callback?: (transaction: Transaction) => void
  ) =>
  async (dispatch: AppDispatch) => {
    transactionsPartialUpdate({ id, requestBody: data })
      .then(function (transaction) {
        dispatch(retrieveTransactionSuccess({ transaction: transaction }));
        callback && callback(transaction);
      })
      .catch(function (ex) {
        handleAPIError(ex, 'Could not update transaction!', dispatch);
      });
  };

export const deleteTransaction =
  (id: number) => async (dispatch: AppDispatch) => {
    transactionsDestroy({ id })
      .then(function (transaction) {
        dispatch(deleteTransactionSuccess(id));
      })
      .catch(function (ex) {
        handleAPIError(ex, 'Could not delete transaction!', dispatch);
      });
  };

export const createTransactionAttachment =
  (id: number, type: string, file: Blob) => async (dispatch: AppDispatch) => {
    return transactionsAttachmentsCreate({
      id: id,
      type: type,
      formData: {
        file: file
      }
    })
      .then(function (transaction) {
        dispatch(retrieveTransactionSuccess({ transaction: transaction }));
      })
      .catch(function (ex) {
        handleAPIError(ex, 'Could not update transaction!', dispatch);
        throw ex;
      });
  };
