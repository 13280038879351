/**
 * Created by mathi on 12-1-2018.
 */
import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Skeleton, Statistic, Typography } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import {
  dashboardStatisticsList,
  Statistic as StatisticType
} from '../services/api';
import dayjs from 'dayjs';
import Page from '../features/UI/components/Page';
import { blue, red, green } from '@ant-design/colors';
const { Text } = Typography;
const Transactionlist = lazy(
  () =>
    import(
      '../features/transactions/components/TransactionList/TransactionList'
    )
);

interface HomeStatisticsCardProps {
  title: string;
  value: number;
  suffix: string;
  precision?: number;
  loading?: boolean;
  extraInfo?: string;
  backgroundColor?: string;
}

function HomeStatisticsCard({
  title,
  value,
  suffix,
  precision,
  loading,
  extraInfo,
  backgroundColor
}: HomeStatisticsCardProps) {
  return (
    <Col xs={12} sm={8} lg={7} xl={6} xxl={4} style={{ minWidth: 150 }}>
      <Card
        bordered={false}
        style={{
          backgroundColor: backgroundColor ?? 'white'
        }}
        bodyStyle={{
          padding: 9
        }}
      >
        <Statistic
          title={title}
          value={value}
          precision={precision || 0}
          suffix={suffix}
          loading={loading}
        />
        {loading ? (
          <Skeleton paragraph={false} title={{ style: { margin: 0 } }} />
        ) : (
          <Text type="secondary" style={{ fontSize: '70%' }}>
            {extraInfo}
          </Text>
        )}
      </Card>
    </Col>
  );
}

function HomeStatistics() {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState<StatisticType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const update = () => {
      dashboardStatisticsList().then((items) => {
        setStatistics(items);
        setLoading(false);
      });
    };
    const interval = setInterval(update, 30 * 1000);
    update();

    return () => clearInterval(interval);
  }, []);

  const lastWeek: StatisticType | null = statistics.filter(
    (item) => item.property == 'NR_OF_DELIVERIES' && item.period == 'LAST_WEEK'
  )[0];
  const thisWeek: StatisticType | null = statistics.filter(
    (item) => item.property == 'NR_OF_DELIVERIES' && item.period == 'THIS_WEEK'
  )[0];
  const thisMonth: StatisticType | null = statistics.filter(
    (item) => item.property == 'NR_OF_DELIVERIES' && item.period == 'THIS_MONTH'
  )[0];

  return (
    <>
      <Row gutter={[16, 16]} justify="center">
        <HomeStatisticsCard
          title={t('dashboard::statistics::deliveries last week')}
          value={lastWeek?.value || 0}
          loading={loading}
          suffix={t('Deliveries')}
          extraInfo={[
            dayjs(lastWeek?.date_range_start).format('DD-MM-YYYY'),
            dayjs(lastWeek?.date_range_end).format('DD-MM-YYYY')
          ].join(' - ')}
        />
        <HomeStatisticsCard
          title={t('dashboard::statistics::deliveries this week')}
          value={thisWeek?.value || 0}
          precision={0}
          loading={loading}
          suffix={t('Deliveries')}
          extraInfo={[
            dayjs(thisWeek?.date_range_start).format('DD-MM-YYYY'),
            dayjs(thisWeek?.date_range_end).format('DD-MM-YYYY')
          ].join(' - ')}
        />
        <HomeStatisticsCard
          title={t('dashboard::statistics::deliveries this month')}
          value={thisMonth?.value || 0}
          precision={0}
          loading={loading}
          suffix={t('Deliveries')}
          extraInfo={[
            dayjs(thisMonth?.date_range_start).format('DD-MM-YYYY'),
            dayjs(thisMonth?.date_range_end).format('DD-MM-YYYY')
          ].join(' - ')}
        />
      </Row>
    </>
  );
}

function Home() {
  const { t } = useTranslation();
  return (
    <Page title={t('Home')} extraContent={<HomeStatistics />}>
      <Row gutter={16} style={{ marginTop: 16 }}>
        <Transactionlist key="transactionList" />
      </Row>
    </Page>
  );
}

export default React.memo(Home);
