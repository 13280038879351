import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../Store';
import {
  Assortment,
  assortmentpricingCreate,
  assortmentpricingDestroy,
  AssortmentPricingRequest,
  assortmentsRetrieve,
  AssortmentWithPrice,
  CancelablePromise,
  Unit,
  unitsList,
  WoodType,
  woodtypesList
} from '../../services/openapi';
import handleAPIError, {
  APIErrorMessage
} from '../../services/api/handleAPIError';

interface AssortmentState {
  wood_types: { [key: number]: WoodType };
  assortments: { [key: number]: AssortmentWithPrice };
  assortments_updating: boolean;
  units: { [key: number]: Unit };
  units_updating: boolean;
}

const initialState: AssortmentState = {
  wood_types: {},
  assortments: {},
  assortments_updating: false,
  units: {},
  units_updating: false
};

const assortmentsSlice = createSlice({
  name: 'assortments',
  initialState: initialState,
  reducers: {
    retrieveAssortmentListStarted(state) {
      state.assortments_updating = true;
    },
    retrieveAssortmentListSuccess(state, action: PayloadAction<Assortment[]>) {
      state.assortments_updating = false;
      state.assortments = {};
      for (var key in action.payload) {
        let assortment = action.payload[key];
        state.assortments[assortment.id] = assortment as any;
      }
    },
    retrieveAssortmentListFailed(
      state,
      action: PayloadAction<APIErrorMessage>
    ) {
      state.assortments_updating = false;
    },
    retrieveWoodTypeListStarted(state) {
      state.assortments_updating = true;
    },
    retrieveWoodTypeSuccess(state, action: PayloadAction<WoodType[]>) {
      state.assortments_updating = false;
      state.assortments = {};
      state.wood_types = {};
      for (let key in action.payload) {
        let wood_type = action.payload[key];
        state.wood_types[wood_type.id] = wood_type;
        for (let k in wood_type.assortments) {
          let assortment = wood_type.assortments[k];
          state.assortments[assortment.id] = assortment;
        }
      }
    },
    retrieveWoodTypeListFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.assortments_updating = false;
    },
    retrieveAssortmentSuccess(
      state,
      action: PayloadAction<AssortmentWithPrice>
    ) {
      state.assortments_updating = false;
      let assortment = action.payload;
      state.assortments[assortment.id] = assortment;
    },
    retrieveAssortmentFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.assortments_updating = false;
    },
    retrieveUnitsStarted(state) {
      state.units_updating = true;
    },
    retrieveUnitsSuccess(state, action: PayloadAction<Unit[]>) {
      state.units_updating = false;
      state.units = {};
      for (let key in action.payload) {
        let unit = action.payload[key];
        state.units[unit.id] = unit;
      }
    },
    retrieveUnitsFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.units_updating = false;
    }
  }
});

export const { retrieveAssortmentSuccess, retrieveAssortmentFailed } =
  assortmentsSlice.actions;
export const {
  retrieveWoodTypeListStarted,
  retrieveWoodTypeSuccess,
  retrieveWoodTypeListFailed
} = assortmentsSlice.actions;
export const {
  retrieveUnitsStarted,
  retrieveUnitsSuccess,
  retrieveUnitsFailed
} = assortmentsSlice.actions;
export default assortmentsSlice.reducer;

let retrieveWoodList: CancelablePromise<WoodType[]>;
export const retrieveWoodTypeList = () => async (dispatch: AppDispatch) => {
  retrieveWoodList && retrieveWoodList.cancel();
  dispatch(retrieveWoodTypeListStarted());
  retrieveWoodList = woodtypesList();

  retrieveWoodList
    .then((data) => {
      dispatch(retrieveWoodTypeSuccess(data));
    })
    .catch((ex) => {
      handleAPIError(
        ex,
        "Couldn't retrieve wood types!",
        dispatch,
        retrieveWoodTypeListFailed
      );
    });
};

export const retrieveAssortment =
  (id: number) => async (dispatch: AppDispatch) => {
    assortmentsRetrieve({ id: id.toString() })
      .then((data) => {
        dispatch(retrieveAssortmentSuccess({ prices: [], ...data }));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't retrieve assortment!",
          dispatch,
          retrieveAssortmentFailed
        );
      });
  };

export const retrieveUnits = () => async (dispatch: AppDispatch) => {
  dispatch(retrieveUnitsStarted());
  unitsList()
    .then((data) => {
      dispatch(retrieveUnitsSuccess(data));
    })
    .catch((ex) => {
      handleAPIError(
        ex,
        "Couldn't retrieve units!",
        dispatch,
        retrieveUnitsFailed
      );
    });
};

export const createAssortmentPrice =
  (data: AssortmentPricingRequest) => async (dispatch: AppDispatch) => {
    assortmentpricingCreate({ requestBody: data })
      .then(function (response) {
        dispatch(retrieveWoodTypeList());
      })
      .catch(function (ex: Error) {
        handleAPIError(ex, "Couldn't create assortment price", dispatch);
      });
  };

export const deleteAssortmentPrice =
  (id: number) => async (dispatch: AppDispatch) => {
    assortmentpricingDestroy({ id: id.toString() })
      .then(function (response) {
        dispatch(retrieveWoodTypeList());
      })
      .catch(function (ex: Error) {
        handleAPIError(ex, "Couldn't delete assortment price", dispatch);
      });
  };
