import { notification } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../Store';
import { selectAllNotifications } from '../UISelectors';
import { useEffect } from 'react';
import { dismissNotification } from '../UISlice';

export default function NotificationSystem() {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectAllNotifications);

  useEffect(() => {
    for (let n of notifications) {
      notification[n.type]({
        key: n.id,
        message: n.title,
        description: n.message,
        onClose: () => {
          dispatch(dismissNotification(n.id));
        },
        placement: n.location,
        duration: n.duration
      });
    }
  }, [notifications]);

  return null;
}
