import { OpenAPI } from '../openapi';
import Cookies from 'js-cookie';
import i18n from '../../i18n/config';
import { authSessionCsrfRetrieve } from '../openapi/services.gen';

// Set API URL and remove trailing slash if necessary
OpenAPI.BASE = (import.meta.env.VITE_API_ROOT as string).replace(/\/$/, '');
OpenAPI.WITH_CREDENTIALS = true;

// Get CSRF Token
const getCSRFToken = async () => {
  return authSessionCsrfRetrieve()
    .then((a) => {})
    .catch((a) => {});
};
getCSRFToken();

// Setup CSRF Token in headers
type Headers = Record<string, string>;
OpenAPI.HEADERS = () =>
  new Promise<Headers>((resolve, reject) => {
    let csrfCookie = Cookies.get('app_csrftoken') || '';
    const headers: Headers = {
      'X-CSRFTOKEN': `${csrfCookie}`
    };
    resolve(headers);
  });

export default OpenAPI;
export * from '../openapi';

export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
